"use client"

import { useCallback, useState } from "react"
import Link from "next/link"
import clsx from "clsx"
import { Widget } from "@typeform/embed-react"

import "styles/retron.css"

import RDLogoIcon from "icons/RDLogo"
import { H3, H6, Retron, SerifH5, Small } from "styles/Type"
import Button from "@/components/Button"
import * as Dialog from "@radix-ui/react-dialog"
import CloseIcon from "@/icons/Close"
import Head from "next/head"

const TYPEFORM_ID = "Uk4EyaF3?typeform-welcome=0"

export default function RootPageClient() {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const onSubmit = useCallback(() => {
    setIsSubmitted(true)
    setIsOpen(false)
  }, [])

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <Head>
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin=""
          href="/assets/fonts/retron2000.woff2"
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin=""
          href="/assets/fonts/PPNeueMontreal-Bold.woff2"
        />
      </Head>
      <div
        className={clsx(
          "inset-0 right-auto flex flex-col text-center justify-between items-center p-7 bg-black",
          "fixed w-full h-full"
        )}
      >
        <video
          className={clsx("fixed inset-0 h-full w-full object-cover")}
          src="/assets/videos/hype_cutdown.mp4"
          autoPlay
          playsInline
          loop
          muted
        />
        <div className="absolute inset-0 bg-black opacity-10" />
        <div
          className={clsx(
            isSubmitted && "opacity-0 translate-y-20",
            "transition duration-500",
            "flex items-center justify-center",
            "w-full"
          )}
        >
          <RDLogoIcon width="107" height="43" className="relative" />
        </div>
        <div className="relative w-full">
          <div
            className={clsx(
              isSubmitted && "absolute opacity-0 translate-y-20",
              "transition duration-500  w-full flex justify-center",
              "w-full"
            )}
          >
            <h1
              className="font-bold leading-[1] text-[1.125rem] max-w-full mx-auto"
              style={{ width: 800 }}
            >
              {"ST_A_RT S_H_A_P_I_N_G_ C_U_LT_URE"
                .split("_")
                .map((s, i) =>
                  s && i % 2 === 0 ? <Retron key={i}>{s}</Retron> : s
                )}
            </h1>
          </div>
          <div
            className={clsx(
              !isSubmitted && "opacity-0 absolute -translate-y-20 invisible",
              "transition-all duration-500",
              "w-full"
            )}
          >
            <div style={{ width: 465 }} className="mx-auto max-w-full">
              <RDLogoIcon width="107" height="43" className="mb-6 mx-auto" />
              <H3 as="p">
                Our membership team will review your submission <br />
                and be in touch shortly.
              </H3>
            </div>
          </div>
          <div
            className={clsx(
              isSubmitted && "opacity-0 translate-y-20 absolute w-full",
              "transition duration-500"
            )}
          >
            <Dialog.Trigger asChild>
              <Button
                as="button"
                type="button"
                theme="white"
                className={clsx("mt-7 py-7 font-semibold uppercase")}
                style={{ width: 260 }}
                size="custom"
              >
                Join the waitlist
              </Button>
            </Dialog.Trigger>
          </div>
          <Small className="mt-5">
            Already a member?{" "}
            <Link href="/login" passHref className="underline">
              Log in
            </Link>
          </Small>
        </div>
        <div className="relative">
          <SerifH5 as="p">By artists, for artists.</SerifH5>
          <H6 as="address" className="not-italic -mb-0.5">
            Los Angeles, CA
          </H6>
        </div>
      </div>
      {isOpen && <div className="fixed inset-0 bg-black opacity-50" />}
      <Dialog.Content
        forceMount
        className={clsx(
          "fixed right-0 top-0 h-full bg-offblack max-w-full",
          !isOpen && "translate-x-full opacity-0",
          "transition duration-500",
          "flex flex-col px-6 md:px-0 justify-center",
          "mr-px"
        )}
        style={{ width: 420 }}
      >
        <Widget
          id={TYPEFORM_ID}
          hideHeaders
          // hideFooter
          opacity={0}
          onSubmit={onSubmit}
          onEndingButtonClick={() => {}}
          className="flex flex-grow"
        />
        <Dialog.Close
          className="lg:hidden absolute top-5 right-5 p-2"
          title="Close"
        >
          <CloseIcon width="24" height="24" />
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Root>
  )
}
