import type { SVGProps } from "react"

export const RDLogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="83"
    height="15"
    viewBox="0 0 83 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M26.975 8.42255V0.000488281H0V13.9762H1.0375V3.11299H25.9985V4.33358H6.89632C5.61471 4.33358 4.39412 4.82181 3.47868 5.73725C2.56324 6.65269 2.075 7.87328 2.075 9.1549C2.075 10.4365 2.56324 11.6571 3.47868 12.5725C4.39412 13.488 5.61471 13.9762 6.89632 13.9762H26.975V9.88725H3.05147V8.42255H26.975Z" />
    <path d="M76.0425 0H56.0249V13.9757H75.9815C77.8124 13.9757 79.6433 13.2434 80.9249 11.9007C82.2676 10.5581 82.9999 8.78823 82.9999 6.95735C82.9999 5.12647 82.2676 3.29559 80.9249 2.01397C79.7043 0.732353 77.8734 0 76.0425 0ZM80.8028 9.88676H57.0624V3.17353H80.8028V9.88676Z" />
    <path d="M49.8613 8.42206H51.6922L54.6216 4.27206H41.4392L39.4863 7.01838L38.3878 5.91985L42.5988 0H35.8245C31.9797 0 28.8062 3.1125 28.8062 7.01838C28.8062 10.8632 31.9187 14.0368 35.8245 14.0368H45.9554L49.8613 8.42206ZM39.1811 3.1125L37.6554 5.24853L35.4584 3.1125H39.1811ZM31.0642 3.1125L37.9606 9.88677L38.9981 8.42206H48.7017L47.6642 9.88677H37.9606H31.0642V3.1125Z" />
  </svg>
)
export default RDLogoIcon
